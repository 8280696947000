export default {
  id: '6f42b216df',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n    <div class="page" data-name="lugarespago">\n      <div class="navbar" style="background-image: url(\'static/images/3131.png\'); background-repeat: no-repeat; ">\n        <div class="navbar-inner sliding">\n          <div class="left">\n            <a href="#" class="link back" style="color:white !important">\n              <i class="icon icon-back" ></i>\n              <span class="if-not-md">Atras</span>\n            </a>\n          </div>\n          <div class="title" style="color:white !important"><i class="material-icons">edit_location</i> Lugares de Pago\n          </div>\n        </div>\n      </div>\n      <div class="page-content">\n          \n      </div>\n    </div>\n  ';
      return r;
    }(this);
  },
  styleScoped: false
};