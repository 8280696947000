export default {
  id: '6fa1f557d0',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page" data-name="cotesinforma">\r\n        <div class="navbar">\r\n            <div class="navbar-inner sliding">\r\n                <div class="left">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon icon-back"></i>\r\n                        <span class="if-not-md">Atras</span>\r\n                    </a>\r\n                </div>\r\n                <div class="title">CotesTv Informa</div>\r\n            </div>\r\n        </div>\r\n        <div class="page-content">\r\n            <div class="block-title">\r\n                <!-- <img src="static/images/informa/info.jpg" class="img_responsive" />  -->\r\n                  <p></p>\r\n                  \r\n            </div>\r\n\r\n        </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};