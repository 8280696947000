export default {
  id: '36b74ffef5',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="guiacomercial">\n    <div class="navbar" style="background-color:#e84e1a">\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back" style="color:white"></i>\n            <span class="if-not-md">Atras</span>\n          </a>\n        </div>\n        <div class="title" style="color:white"><i class="material-icons">collections</i> Guía Comercial\n        </div>\n      </div>\n    </div>\n    <div class="page-content">\n        <div id="makemat" class="iframe-container iframe-container-for-wxh-500x350">\n            <iframe src="https://www.cotes.net.bo/appCotes/public/appWeb_/amarillas.html" marginheight="1" height="1" frameborder="0" allowfullscreen>  </iframe>\n    \n          </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};