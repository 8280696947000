export default {
  data: function () {
  },
  methods: {
    combo1: function () {
      const self = this;
      self.c1.open();
    },
    combo2: function () {
      const self = this;
      self.c2.open();
    }
  },
  on: {
    pageInit: function () {
      const self = this;
      self.c1 = self.$app.photoBrowser.create({
        photos: [{ url: 'static/images/combos/combo.png' }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
      self.c2 = self.$app.photoBrowser.create({
        photos: [{ url: 'static/images/combos/combo2.png' }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
    },
    pageBeforeRemove: function () {
      const self = this;
      self.c1.destroy();
      self.c2.destroy();
    }
  },
  id: 'a0c1f82e61',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="combos">\n    <div class="navbar" style="background-color:#d50911">\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back" style="color:white"></i>\n            <span class="if-not-md">Atras</span>\n          </a>\n        </div>\n        <div class="title" style="color:white"><i class="material-icons">playlist_play</i> Combos Cotes\n        </div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="block" style="text-align:center; ">\n        <img src="static/images/combos/logo_combo.png" class="logo_responsive" />\n      </div>\n      <div class="block" style="text-align:center; ">\n        <p></p>\n        <a href="#" @click="combo1" class="col button button-large button-raised button-fill"\n          style="background-color: #d50911; width: 100%"><i class="material-icons view_quilt"></i> Combos Digitales</a>\n        <p></p>\n\n      </div>\n      <div style="text-align: center">\n      <img src="static/images/contactar.png" class="logo_responsive" />\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};