export default {
  data: function () {
  },
  methods: {
    planes_tarifas: function () {
      const self = this;
      self.pt.open();
    },
    recomendaciones: function () {
      const self = this;
      self.rq.open();
    },
    wifi: function () {
      const self = this;
      self.wi.open();
    },
    empresa: function () {
      const self = this;
      self.em.open();
    }
  },
  on: {
    pageInit: function () {
      const self = this;
      self.pt = self.$app.photoBrowser.create({
        photos: [{ url: 'static/images/internet/inter1.png' }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
      self.rq = self.$app.photoBrowser.create({
        photos: [
          { url: 'static/images/internet/recomendacion1.png' },
          { url: 'static/images/internet/recomendacion2.png' }
        ],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
      self.wi = self.$app.photoBrowser.create({
        photos: [
          { url: 'static/images/wifi/portada.jpg' },
          { url: 'static/images/wifi/wifi1.jpg' },
          { url: 'static/images/wifi/wifi2.jpg' },
          { url: 'static/images/wifi/wifi3.jpg' }
        ],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
      self.em = self.$app.photoBrowser.create({
        photos: [{ url: 'static/images/internet/inter2.png' }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
    },
    pageBeforeRemove: function () {
      const self = this;
      self.pt.destroy();
      self.rq.destroy();
      self.wi.destroy();
      self.em.destroy();
    }
  },
  id: 'd94e2c87a7',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="internet">\n    <div class="navbar" style="background-color:#562578">\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back" style="color:white"></i>\n            <span class="if-not-md">Atras</span>\n          </a>\n        </div>\n        <div class="title" style="color:white"><i class="material-icons">network_check</i> Internet\n        </div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="block" style="text-align:center; ">\n        <img src="static/images/internet_logo.png" class="logo_responsive" />\n      </div>\n      <div class="block" style="text-align:center; ">\n        <p>\n          <h2>DOMICILIARIO</h2>\n        </p>\n        <p></p>\n        <a href="#" @click="planes_tarifas" class="col button button-large button-raised button-fill"\n          style="background-color: #562578; width: 100%"><i class="material-icons view_quilt"></i> Nuestros Planes</a>\n        <p></p>\n        <a href="#" @click="recomendaciones" class="col button button-large button-raised button-fill"\n          style="background-color: #562578; width: 100%">Consejos y Recomendaciones</a>\n          <p></p>\n          <a href="#" @click="wifi" class="col button button-large button-raised button-fill"\n            style="background-color: #562578; width: 100%">Zonas Wifi</a>\n      </div>\n      <div class="block" style="text-align:center; ">\n        <p>\n          <h2>EMPRESAS</h2>\n        </p>\n        <p></p>\n        <a href="#" @click="empresa" class="col button button-large button-raised button-fill"\n          style="background-color: #562578; width: 100%">Nuestros Planes</a>\n        <p></p>\n      </div>\n      <div style="text-align: center">\n        <img src="static/images/contactar.png" class="logo_responsive" />\n        </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};