export default {
  data: function () {
  },
  methods: {
    c1: function () {
      const self = this;
      self.co1.open();
    },
    c2: function () {
      const self = this;
      self.co2.open();
    },
    c3: function () {
      const self = this;
      self.co3.open();
    }
  },
  on: {
    pageInit: function () {
      const self = this;
      self.co1 = self.$app.photoBrowser.create({
        photos: [{
            html: '<iframe src="https://www.youtube.com/embed/a3MbGFasmPc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
            caption: ''
          }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
      self.co2 = self.$app.photoBrowser.create({
        photos: [{
            html: '<iframe src="https://www.youtube.com/embed/oi0II90a97Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
            caption: ''
          }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
      self.co3 = self.$app.photoBrowser.create({
        photos: [{
            html: '<iframe src="https://www.youtube.com/embed/jD0k-4HeLK0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
            caption: ''
          }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
    },
    pageBeforeRemove: function () {
      const self = this;
      self.co1.destroy();
      self.co2.destroy();
      self.co3.destroy();
    }
  },
  id: '78127aebb8',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="corona">\n    <div class="navbar" style="background-color:#d50911">\n      <div class="navbar-inner sliding">\n        <div class="left">\n          <a href="#" class="link back">\n            <i class="icon icon-back" style="color:white"></i>\n            <span class="if-not-md">Atras</span>\n          </a>\n        </div> \n        <div class="title" style="color:white">  Medidas Coronavirus\n        </div>\n      </div>\n    </div>\n    <div class="page-content">\n      <div class="block" style="text-align:center; ">\n      \n      </div>\n      <div class="block" style="text-align:center; ">\n        <p></p>\n        <a href="#" @click="c1" class="col button button-large button-raised button-fill"\n          style="background-color: #d50911; width: 100%"><i class="material-icons view_quilt"></i> Aprende a protegerte</a>\n        <p></p>\n        <a href="#" @click="c2" class="col button button-large button-raised button-fill"\n          style="background-color: #d50911; width: 100%">Lavado correcto de manos</a>\n        <p></p>\n        <a href="#" @click="c3" class="col button button-large button-raised button-fill"\n          style="background-color: #d50911; width: 100%">Consejos para prevenir la diseminacion del virus</a>\n      </div>\n\n\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};