export default {
  data: function () {
  },
  methods: {
    promo1: function () {
      const self = this;
      self.p1.open();
    },
    promo2: function () {
      const self = this;
      self.p2.open();
    }
  },
  on: {
    pageInit: function () {
      const self = this;
      self.p1 = self.$app.photoBrowser.create({
        photos: [{ url: 'static/images/promociones/dos.jpg' }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
      self.p2 = self.$app.photoBrowser.create({
        photos: [{ url: 'static/images/promociones/promo20.png' }],
        type: 'popup',
        theme: 'dark',
        navbarOfText: 'de'
      });
    },
    pageBeforeRemove: function () {
      const self = this;
      self.p1.destroy();
      self.p2.destroy();
    }
  },
  id: '39e6694eed',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page" data-name="promociones">\r\n        <div class="navbar">\r\n            <div class="navbar-inner sliding">\r\n                <div class="left">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon icon-back"></i>\r\n                        <span class="if-not-md">Atras</span>\r\n                    </a>\r\n                </div>\r\n                <div class="title"> Promociones\r\n                </div>\r\n            </div>\r\n        </div>\r\n        <div class="page-content">\r\n          <a href="#" @click="promo1" class="item-link item-content">\r\n                <img src="static/images/promociones/dos.jpg" class="img_responsive" />\r\n            </a>\r\n           <!--  <a href="#" @click="promo2" class="item-link item-content">\r\n              <img src="static/images/promociones/promo2_1.jpg" class="img_responsive" />\r\n          </a>\r\n            <p></p>\r\n--> \r\n        </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};