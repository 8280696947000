
import HomePage from '../pages/home.f7.html';
import CotestvlivePage from '../pages/cotestvlive.f7.html';
import Cotesinforma from '../pages/cotesinforma.f7.html';
import Promociones from '../pages/promociones.f7.html';
import Internet from '../pages/internet.f7.html';
import Telefonia from '../pages/telefonia.f7.html';
import Television from '../pages/television.f7.html';
import Guia from '../pages/guiatelefonica.f7.html';
import Amarillas from '../pages/guiacomercial.f7.html';
import Grilla from '../pages/grilla.f7.html';
import Guiatv from '../pages/guiatv.f7.html';
import Deuda from '../pages/deuda.f7.html';
import Lugares from '../pages/lugarespago.f7.html';
import Legales from '../pages/servicioslegales.f7.html';
import Telefonos from '../pages/telefonoscotes.f7.html';
import Cotescombos from '../pages/combos.f7.html';

import AboutPage from '../pages/about.f7.html';
import FormPage from '../pages/form.f7.html';

import LeftPage1 from '../pages/left-page-1.f7.html';
import LeftPage2 from '../pages/left-page-2.f7.html';
import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

import Corona from '../pages/corona.f7.html';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/combos/',
    component: Cotescombos,
  },
  {
    path: '/cotestvlive/',
    component: CotestvlivePage,
  },
  {
    path: '/cotesinforma/',
    component: Cotesinforma,
  },
  {
    path: '/promociones/',
    component: Promociones,
  },
  {
    path: '/internet/',
    component: Internet,
  },
  {
    path: '/telefonia/',
    component: Telefonia,
  },
  {
    path: '/television/',
    component: Television,
  },
  {
    path: '/guiatelefonica/',
    component: Guia,
  },
  {
    path: '/guiacomercial/',
    component: Amarillas,
  },
  {
    path: '/grilla/',
    component: Grilla,
  },
  {
    path: '/guiatv/',
    component: Guiatv,
  },
  {
    path: '/deuda/',
    component: Deuda,
  },
  {
    path: '/lugarespago/',
    component: Lugares,
  },
  {
    path: '/servicioslegales/',
    component: Legales,
  },
  {
    path: '/telefonoscotes/',
    component: Telefonos,
  },


  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/corona/',
    component: Corona,
  },
  {
    path: '/left-page-1/',
    component: LeftPage1,
  },
  {
    path: '/left-page-2/',
    component: LeftPage2,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;