export default {
  id: '9ac035f94e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n        <div class="page" data-name="cotestvlive">\r\n          <div class="navbar" style="background-image: url(\'static/images/3131.png\'); background-repeat: no-repeat; ">\r\n            <div class="navbar-inner sliding">\r\n              <div class="left">\r\n                <a href="#" class="link back" style="color:white !important">\r\n                  <i class="icon icon-back" ></i>\r\n                  <span class="if-not-md">Atras</span>\r\n                </a>\r\n              </div>\r\n              <div class="title" style="color:white !important"><i class="material-icons">live_tv</i> CotesTv Live\r\n              </div>\r\n            </div>\r\n          </div>\r\n          <div class="page-content">\r\n                <div class="block-title">Disfrute de nuestra programación en vivo:</div>\r\n                <div class="iframe-container">\r\n                        <iframe src="https://iframe.dacast.com/b/129582/c/499826" width="590" height="431" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>\r\n                </div>\r\n          </div>\r\n        </div>\r\n      ';
      return r;
    }(this);
  },
  styleScoped: false
};